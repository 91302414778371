import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FetchActivities } from "../Network/ActivityApi";
import { FetchBooks } from "../Network/BookApi";
import { FetchClientData } from "../Network/ClientApi";
import { FetchStaffs, FetchStudents } from "../Network/CredApi";
import { FetchExams } from "../Network/ExamApi";
import { FetchExpenses } from "../Network/ExpenseApi";
import { FetchExpenseCats } from "../Network/ExpenseCatApi";
import { FetchIntervals } from "../Network/IntervalApi";
import { FetchInvoices } from "../Network/InvoiceApi";
import { FetchLessons } from "../Network/LessonApi";
import { FetchLibraryCats } from "../Network/LibraryCatApi";
import { FetchModes } from "../Network/ModeApi";
import { FetchPayments } from "../Network/PaymentApi";
import { FetchLocalStuctures } from "../Network/StructureApi";
import { FetchSubjects } from "../Network/SubjectApi";
import { FetchStoreCats } from "../Network/StoreCatApi";
import { FetchStoreEntries } from "../Network/StoreEntryApi";
import { FetchItems } from "../Network/ItemApi";
import { FetchSubs } from "../Network/SubApi";
import { CheckLiveUser, LiveLogIn, RegisterUser } from "../Network/AuthApi";
import { login } from "../reducers/AuthSlice";
import { RegisterLiveInst } from "../Network/InstApi";
import { SocketJoinRoom, socket } from "./SockectConnect";
import { FetchGrades } from "../Network/GradeApi";
import { FetchOnlinePays } from "../Network/OnlinePayApi";
import { FetchMpesaPayments } from "../Network/MpesaApi";
import { FetchMessages } from "../Network/MessageApi";

const FetchData = () => {
	//redux dispatch
	const dispatch = useDispatch();

	const TheUser = useSelector((state) => state.auth.user);
	const ActiveCred = useSelector((state) => state.cred.activeCred);
	const online = useSelector((state) => state.online.online);
	const ActiveInst = useSelector((state) => state.inst.activeInst);
	const creds = useSelector((state) => state.cred.creds);
	const [Loading, setLoading] = useState(false);

	const [Connected, setConnected] = useState(false);

	useEffect(() => {
		UpdateData();
	}, [Connected, TheUser]);

	const ConnectRooms = () => {
		SocketJoinRoom({
			clientId: `${parseInt(
				parseInt(`${ActiveInst.linker}`.split("").reverse().join("")) / 1000
			)}`,
		});
		creds.map((cred) => {
			if (cred.email == TheUser.logger || cred.contact == TheUser.logger) {
				SocketJoinRoom({
					token: TheUser.token,
					instLinker: cred.instLinker,
					room: cred.instLinker,
				});
			}
		});
	};

	const UpdateData = async () => {
		if (!Loading) {
			setLoading(true);
			//live user
			let User = !TheUser.token ? await RegisterLiveUser() : TheUser;

			if (!ActiveInst.id || ActiveInst.id == 0) {
				await RegisterLiveInst(ActiveInst, User, dispatch);
			}

			FetchData(User);

			setLoading(false);
		}

		socket.on("connect", () => {
			console.log(`connected`);
			setConnected(true);
		});

		socket.on("disconnect", () => {
			setConnected(false);
		});

		ConnectRooms();
	};

	const RegisterLiveUser = async () => {
		let liveUser = await CheckLiveUser(TheUser.logger);
		let UserLive;
		if (liveUser === "register" || liveUser === 0) {
			UserLive = await RegisterUser(
				TheUser.logger,
				TheUser.password,
				TheUser.linker,
				TheUser.linker
			);
		} else {
			UserLive = await LiveLogIn(TheUser.logger, TheUser.password);
		}

		if (UserLive.token && UserLive.user) {
			dispatch(
				login({
					name: TheUser.name,
					email: TheUser.logger,
					password: TheUser.password,
					linker: TheUser.linker,
					live: 1,
					token: UserLive.token,
					logger: TheUser.logger,
					id: UserLive.user.id,
				})
			);

			return {
				name: TheUser.name,
				email: TheUser.logger,
				password: TheUser.password,
				linker: TheUser.linker,
				live: 1,
				token: UserLive.token,
				logger: TheUser.logger,
				id: UserLive.user.id,
			};
		}
		return TheUser;
	};

	const FetchData = (User) => {
		if (
			parseInt(ActiveCred.admin) === 1 ||
			parseInt(ActiveCred.finance) === 1
		) {
			FetchPayments(User.token, ActiveCred.instLinker, dispatch, online);
			FetchInvoices(User.token, ActiveCred.instLinker, dispatch, online);
			FetchExpenses(User.token, ActiveCred.instLinker, dispatch, online);
			FetchExpenseCats(User.token, ActiveCred.instLinker, dispatch, online);
			FetchOnlinePays(User.token, ActiveCred.instLinker, dispatch, online);
			FetchMpesaPayments(User.token, ActiveCred.instLinker, dispatch, online);
		}
		if (
			parseInt(ActiveCred.admin) === 1 ||
			parseInt(ActiveCred.finance) === 1 ||
			parseInt(ActiveCred.tutor) === 1 ||
			parseInt(ActiveCred.library) === 1
		) {
			FetchBooks(User.token, ActiveCred.instLinker, dispatch, online);
			FetchStudents(ActiveCred.instLinker, User.token, dispatch, online);
			FetchExams(User.token, ActiveCred.instLinker, dispatch, online);
			FetchStoreCats(User.token, ActiveCred.instLinker, dispatch, online);
			FetchStoreEntries(User.token, ActiveCred.instLinker, dispatch, online);
			FetchItems(User.token, ActiveCred.instLinker, dispatch, online);
			FetchStaffs(ActiveCred.instLinker, User.token, dispatch, online);
			FetchStudents(ActiveCred.instLinker, User.token, dispatch, online);
			FetchMessages(User.token, ActiveCred.instLinker, dispatch, online);
		}

		if (
			parseInt(ActiveCred.student) === 1 ||
			parseInt(ActiveCred.guardian) === 1
		) {
			FetchClientData(
				User.token,
				ActiveCred.instLinker,
				dispatch,

				"payments"
			);
			FetchClientData(
				User.token,
				ActiveCred.instLinker,
				dispatch,

				"invoices"
			);
			FetchClientData(
				User.token,
				ActiveCred.instLinker,
				dispatch,

				"exams"
			);
			FetchClientData(
				User.token,
				ActiveCred.instLinker,
				dispatch,

				"books"
			);
		}
		FetchActivities(ActiveCred.instLinker, User.token, dispatch, online);
		FetchIntervals(User.token, ActiveCred.instLinker, dispatch, online);
		FetchLessons(User.token, ActiveCred.instLinker, dispatch, online);
		FetchGrades(User.token, ActiveCred.instLinker, dispatch, online);
		FetchLibraryCats(User.token, ActiveCred.instLinker, dispatch, online);
		FetchModes(User.token, ActiveCred.instLinker, dispatch, online);
		FetchLocalStuctures(ActiveCred.instLinker, User.token, dispatch, online);
		FetchSubjects(ActiveCred.instLinker, User.token, dispatch, online);
		FetchSubs(User.token, ActiveCred.instLinker, dispatch, online);
	};

	return <></>;
};

export default FetchData;
